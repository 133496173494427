<template>
    <div>
        <div class="title">
            <app-header :title="title" :isShowBack="true"></app-header>
        </div>
        <div style="margin:30px">
            <div style="margin-bottom:10px">已选{{selectedData.length}}个货柜</div>
            <el-table  height="600" row-key="counterId" style="width: 100%"  :data="selectedData.slice((currentPage-1)*pageSize,currentPage*pageSize)">
                <el-table-column prop="provinceStr" label="省份"></el-table-column>
                <el-table-column prop="cityStr" label="城市"></el-table-column>
                <el-table-column prop="groupName" label="门店"></el-table-column>
                <el-table-column prop="chargeName" label="门店负责人"></el-table-column>
                <el-table-column prop="chargePhone" label="门店负责人电话"></el-table-column>
                <el-table-column prop="counterName" label="售货柜"></el-table-column>
            </el-table>
            <div style="margin-top:10px">
               <el-pagination
                    @size-change="sizeChange"
                    @current-change="currentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination> 
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            title: "查看授权货柜",
            selectedData:[],
            cityList: [],
            provinceList:[],
            currentPage: 1,
            pageSize:10,
            total:0,
        }
    },
    activated(){
        this.getProvinceList();
        this.getCityList();
        // this.getSelected();
        this.currentPage = 1;
        this.pageSize = 10;
        this.total = 0;
    },
    methods: {
        getSelected(){
            this.post('mall-service/v1/get-counter-auth?userId=' + this.$route.query.userId, {
                isUseResponse: true,
            }).then(res => {
                if(res.length > 0){
                    res.forEach(item =>{
                        item.counterName = item.counterName?item.counterName:'--'
                        item.chargeName = item.chargeName?item.chargeName:'--'
                        item.chargePhone = item.chargePhone?item.chargePhone:'--'
                        item.cityStr = this.getCityName(item.city);
                        item.provinceStr = this.getProvinceName(item.province);
                    })
                }
                this.selectedData = res
                this.total = this.selectedData.length
            }).catch(err => {

            })
        },
        // 获取城市列表
        getCityList(name) {
            return new Promise((resolve, reject) => {
                this.post("/landlord-service/base/getCitys").then(res => {
                    res.forEach(item => {
                        if (item.cityCode) {
                            if (item.cityCode.length == 2) {
                                item.cityCode = '0' + item.cityCode
                            } else if (item.cityCode.length == 1) {
                                item.cityCode = '00' + item.cityCode
                            }
                        }
                    })
                    this.cityList = res;
                    this.getSelected()
                    resolve(res);
                });
            })
        },
        // 根据城市编码知道成功名字
        getCityName(val) {
            for (let i = 0; i < this.cityList.length; i++) {
                if (val == this.cityList[i].cityCode) {
                    return this.cityList[i].name
                }
            }
        },
        getProvinceList() {
            return new Promise((resolve, reject) => {
                this.get('/landlord-service/base/getTree').then(res => {
                    this.provinceList = res;
                    this.getSelected()
                    resolve(res);
                });
            })
        },
        getProvinceName(val) {
            for (let i = 0; i < this.provinceList.length; i++) {
                if (val == this.provinceList[i].value) {
                    return this.provinceList[i].label
                }
            }
        },
        currentChange(val) {
            this.currentPage = val;
        },
        sizeChange(val) {
            this.pageSize = val;
        }

    }
}
</script>
<style scoped lang='scss'>
    .title {
        position: relative;
        top: 20px;
        left: 20px;
    }
</style>